import { createReducer } from '@reduxjs/toolkit';
import { MqttClient } from 'mqtt';

import {
    ADD,
    CLEAN_CALLS,
    SET_CLIENT,
    SET_CONNECTION_ENDED,
    SET_CONNECTION_STATUS,
} from '../actions/actions';
import { ICallData, IConnectionStatus } from '../types';
import { end } from '../utils/mqtt';

export interface CallsState {
    calls: ICallData[];
    client?: MqttClient;
    connectionStatus?: IConnectionStatus;
}

const initialState: CallsState = {
    calls: [],
    client: undefined,
    connectionStatus: undefined,
};

const otherPlace = (aCall: ICallData) => (otherCall: ICallData) => aCall.place !== otherCall.place;

const existCall = (aCall: ICallData) => (otherCall: ICallData) => aCall.id === otherCall.id;

const filterByNonMatchingAppointmentId = (aCall: ICallData) => (otherCall: ICallData) =>
    aCall.id !== otherCall.id;

const olderThat = (date: Date) => (otherCall: ICallData) => otherCall.time > date;

const MAX_CALLS = 4;

const limitCallsSize = (calls: ICallData[]) =>
    calls.length === MAX_CALLS ? calls.slice(1) : calls;

const parseNullPatientName = (call: ICallData) =>
    call.patient === 'null null' ? (call.patient = 'Paciente temporal') : null;

const callsReducer = createReducer(initialState, {
    [ADD]: (state, action) => {
        const newCall: ICallData = action.payload;

        parseNullPatientName(newCall);

        if (state.calls.find(existCall(newCall))) {
            state.calls = [
                newCall,
                ...state.calls.filter(filterByNonMatchingAppointmentId(newCall)),
            ];

            return;
        }

        const calls = [newCall, ...state.calls.filter(otherPlace(newCall))];

        state.calls = limitCallsSize(calls);
    },
    [CLEAN_CALLS]: (state, action) => {
        state.calls = state.calls.filter(olderThat(action.payload));
    },
    [SET_CLIENT]: (state, action) => {
        state.client = action.payload;
    },
    [SET_CONNECTION_STATUS]: (state, action) => {
        state.connectionStatus = { type: action.payload };
    },
    [SET_CONNECTION_ENDED]: (state) => {
        let client = state.client;

        if (client) end(client);
        state.client = undefined;
    },
});

export default callsReducer;
