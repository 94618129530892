import React, { useEffect, useContext } from 'react';
import { Stack, Box, Text } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { addMinutes } from 'date-fns';

import {
    endConnection as actionEndConnection,
    mqttConnect as actionMqttConnect,
    cleanCalls as actionCleanCalls,
} from '../actions';
import { ICallData, IConfig, ConfigJsonModel } from '../types';
import LastCalled from '../components/LastCalled';
import CallsTable from '../components/CallsTable';
import VideoComponent from '../components/VideoComponent';
import useInterval from '../hooks/useInterval';
import { EntityConfigContext } from '../context/EntityConfigContext';

import ConnectionStatus from './ConnectionStatus';
import './Config.css';

interface TurneroProps {
    calls: ICallData[];
    lastCall: ICallData;
    config: IConfig;
    mqttConnect: () => any;
    endConnection: () => any;
    cleanCalls: (date: Date) => any;
}

const Turnero = ({
    calls,
    lastCall,
    config,
    mqttConnect,
    endConnection,
    cleanCalls,
}: TurneroProps) => {
    const EntityConfig: ConfigJsonModel = useContext(EntityConfigContext);

    useEffect(() => {
        mqttConnect();

        return () => endConnection();
    }, [mqttConnect, endConnection]);

    useInterval(() => {
        cleanCalls(addMinutes(new Date(), -30));
    }, 60000);

    return (
        <React.Fragment>
            <Stack direction="row">
                <Stack mr="2px" width={config.video ? '50%' : '100%'}>
                    <LastCalled lastCall={lastCall} />
                    {!!calls.length && (
                        <Stack
                            alignItems="baseline"
                            bg="white"
                            borderRadius="10px"
                            boxShadow="md"
                            flex={1}
                            justifyContent={!calls.length ? 'center' : 'flex-start'}
                            paddingY={4}
                        >
                            <Box
                                bg={EntityConfig?.tablaTurnero?.labelColor}
                                borderRadius="10px"
                                color="white"
                                fontSize={16}
                                left="30px"
                                paddingX={4}
                                paddingY={1}
                                pos="relative"
                            >
                                <Text casing="uppercase" fontWeight={700}>
                                    llamados anteriores
                                </Text>
                            </Box>
                            <CallsTable calls={calls} />
                        </Stack>
                    )}
                    <ConnectionStatus />
                </Stack>
                {config.video && <VideoComponent link={config.video} />}
            </Stack>
        </React.Fragment>
    );
};

const mapStateToProps = (state: { calls: { calls: ICallData[] }; config: { config: IConfig } }) => {
    const [lastCall, ...calls] = state.calls.calls;
    // const [lastCall, ...calls] = MockICallData;
    const config = state.config;

    return { lastCall, calls, config };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        mqttConnect: () => dispatch(actionMqttConnect()),
        endConnection: () => dispatch(actionEndConnection()),
        cleanCalls: (date: Date) => dispatch(actionCleanCalls(date)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Turnero);
